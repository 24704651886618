import React, { useState } from 'react'
import { Link } from 'gatsby'

import img1 from '../../assets/images/coronini-caffe/coronini_img1.png'
import img2 from '../../assets/images/coronini-caffe/coronini_img2.png'
import img3 from '../../assets/images/coronini-caffe/coronini_img3.png'
import img4 from '../../assets/images/coronini-caffe/coronini_img4.png'
import img5 from '../../assets/images/coronini-caffe/coronini__img5.png'

import imgTeamContact from '../../assets/images/img-team-2.png'

import SEO from '../../components/SEO'
import Header from '../../components/Header'
import TagsMarquee from '../../components/TagsMarquee'
import MintButtons from '../../components/MintButtons'
import ShareButtons from '../../components/ShareButtons'

import { theme, colors } from '../../constants/theme'

import {
  ProjectDetailsLayout,
  SectionCover,
  SectionIntro,
  SectionRichText,
  SectionCounterImage,
  SectionCounterGrid,
  SectionFullImage,
  SectionImagesGrid,
  SectionFooter,
  SectionNavigator,
  StickyButtons,
  Square,
} from '../../styles/ProjectDetailsLayout'
import { Content } from '../../styles/PageLayout'
import {
  HeadlineL,
  HeadlineM,
  TitleL,
  TitleM,
  SubtitleM,
  ParagraphL,
  ParagraphM,
  ParagraphS,
} from '../../styles/Typography'

/**
 * A project details template, to be used in gatsby-node.
 * Includes sections that can be conditionally rendered.
 * @returns {StatelessComponent}
 */
const ProjectDetails = () => {
  const [selected, setSelected] = useState(0)

  const tags = [
    'Družbena omrežja',
    'E-mail oglaševanje',
    'Tekstopisje',
    'Grafično oblikovanje',
    'Družbena omrežja',
    'E-mail oglaševanje',
    'Tekstopisje',
    'Grafično oblikovanje',
    'Družbena omrežja',
    'E-mail oglaševanje',
    'Tekstopisje',
    'Grafično oblikovanje',
  ]

  return (
    <>
      <SEO
        title="Mint and Square | Coronini Caffe"
        description="Za mikropražarno vodimo in ustvarjamo vsebine za družbena omrežja. Skrbimo tudi za grafično oblikovanje in pošiljanje mesečnega e-novičnika."
        image={img1}
      />
      <Header
        logoBackground={colors.purple}
        logoBorder={colors.purple}
        linkText={colors.purple}
        menuLines={colors.purple}
      />

      <ProjectDetailsLayout>
        {/* Cover Section - Aways visible */}
        <SectionCover>
          <div className="cover-image">
            <img src={img1} alt="Coronini Caffe" />
          </div>
          <Square background={colors.red} className="title">
            <ParagraphM color={colors.white}>T & R Kava</ParagraphM>
            <HeadlineM color={colors.purple}>Coronini Caffe</HeadlineM>
          </Square>
          <div className="tags">
            <TagsMarquee tags={tags} />
          </div>
        </SectionCover>

        {/* Intro Section - Aways visible */}
        <SectionIntro background={colors.purple}>
          <Content>
            <TitleM color={colors.white}>Izziv</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Preusmeriti fokus iz b2b na b2c segment zaradi zaprtja gostinstva v času kovida.
            </ParagraphL>
          </Content>
          <Content>
            <TitleM color={colors.white}>Opis</TitleM>
            <ParagraphL color={colors.white} weight="300">
              Za mikropražarno vodimo in ustvarjamo vsebine za družbena omrežja. Skrbimo tudi za
              grafično oblikovanje in pošiljanje mesečnega e-novičnika.
            </ParagraphL>
          </Content>
        </SectionIntro>

        <div className="section-wrapper">
          {/* Sticky Share and Mint buttons - Aways visible */}
          <StickyButtons>
            <MintButtons isColumn selected={selected} setSelected={setSelected} />
            <ShareButtons isColumn />
          </StickyButtons>

          {/* RichText Section - Conditionally visible */}
          <SectionRichText className="reduce-top-padding">
            <Content>
              {/* Example Title */}
              <h2>Grofovska kava</h2>

              {/* Example Paragraph */}
              <p>
                S Coronini Caffe smo začeli sodelovati marca 2020, ko so se prvič zaprli gostinski
                lokali, ki so predstavljali velik del prodaje kave. Takrat smo naročniku pomagali
                zgraditi boljšo prepoznavnost znamke pri končnih kupcih in povišati spletno prodajo.
              </p>

              {/* Example Paragraph */}
              <p>
                Od takrat skrbimo za urejanje družbenih omrežij, v sodelovanju z naročnikom kreiramo
                prodajne akcije, skrbimo za ustvarjanje kreativ in grafično oblikovanje raznoraznih
                materialov.
              </p>

              {/* Example Paragraph */}
              <p>
                Če nas obiščete v naši pisarni, vam bomo postregli z našo najljubšo Coronini 100%
                premium arabico. ☕️
              </p>
            </Content>
          </SectionRichText>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img2} alt="Coronini Caffe" style={{ marginBottom: '4rem' }} />
          </SectionFullImage>

          {/* CounterImages Section - Conditionally visible */}
          <SectionCounterImage>
            <div className="image">
              <img src={img3} alt="Coronini Caffe" />
            </div>
            <Square background={colors.purple} className="counter">
              <HeadlineL color={colors.white} font={theme.fontSecondary}>
                1000%
              </HeadlineL>
              <SubtitleM color={colors.white}>povečanje spletne prodaje od 2019 do 2021</SubtitleM>
            </Square>
          </SectionCounterImage>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img4} alt="Coronini Caffe" />
          </SectionFullImage>

          {/* FullImage Section - Conditionally visible */}
          <SectionFullImage>
            <img src={img5} alt="Coronini Caffe" />
          </SectionFullImage>
        </div>

        {/* Footer Section - Aways visible */}
        <SectionFooter>
          <ParagraphL>Kako se ti je zdel ta projekt?</ParagraphL>
          <div className="mint-buttons">
            <MintButtons selected={selected} setSelected={setSelected} />
          </div>
          <div className="share-buttons">
            <ShareButtons />
          </div>
          <div className="contact">
            <img src={imgTeamContact} alt="Contact Team" />
            <ParagraphS weight="300">
              Živjo, sem Romina. Ustanoviteljica Mint & Square agencije in kreativna direktorica.
              Želiš več informacij o tej objavi, ali pa stopiti v kontakt? Lahko mi napišeš kar na{' '}
              {''}
              <a href="https://www.linkedin.com/in/romina-gerbec/" target="_blank">
                LinkedIn
              </a>{' '}
              👌
            </ParagraphS>
          </div>
        </SectionFooter>

        <SectionNavigator>
          <Link to="/project/butik-festival">
            <ParagraphL>← Prejšnji projekt </ParagraphL>
          </Link>
          <Link to="/project/visit-good-place">
            <ParagraphL>Naslednji projekt →</ParagraphL>
          </Link>
        </SectionNavigator>
      </ProjectDetailsLayout>
    </>
  )
}
export default ProjectDetails
